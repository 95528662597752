import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Login } from './views/Login';



Sentry.init({
    dsn: "https://5e99c41be2674e478cd8a3398ece9392@o719297.ingest.sentry.io/5781010",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

//create a login page and render that element here.. from there render App?
ReactDOM.render(<Login />, document.getElementById("root"));
