import React from "react";

const PriceTarget = (props) => {
  return (
    <div className="overflow-scroll">
    <div className="card text-left m-3">
      {props.pricetarget.filter((item, idx) => idx < 200).map(item => (
               <div className="card-body scroll">
                <a href={item.NewsURL} target="_blank">{item.NewsTitle}</a> 
                <br></br>            
                <b> PriceTarget: {item.PriceTarget}</b> <br></br>
                PriceWhenPosted: {item.PriceWhenPosted}
                <br></br>
                <i> Analyst: {item.AnalystName} from {item.AnalystCompany}</i><br></br>
                <a href={item.NewsBaseURL} target="_blank">{item.NewsPublisher} </a> &nbsp; &nbsp; 
                {item.PublishedDate}
              </div>
            ))}
    </div>
    </div>
    );
  }

  export default PriceTarget;