import React from "react";
import Table from "./Table"

const Reports = (props) => {

    const theadData = [
      "FiscalDate", 
      "TotalRevenue",
      "ReveneGrowthYoY",
      "CostofRevenue",
      "GrossProfit",
      "GrossProfitMargin",
      "OperatingExpenses",
      "NetIncome"
    ];
  
    return (
      <div className="card">
      <div className="card-body">
      <h5 className="card-title">Quarterly Reports</h5>
        <Table theadData={theadData} tbodyData={props.reports} />
      </div>
      </div>  
    );
  
  }

  export default Reports;