import React from "react";
import LinesEllipsis from 'react-lines-ellipsis'

const PressReleases = (props) => {
  return (
    <div className="overflow-scroll">
    <div className="card text-left m-3">
      {props.pressreleases.filter((item, idx) => idx < 200).map(item => (
               <div className="card-body scroll">
                <b>{item.Title} </b>
                <br></br>            
                <LinesEllipsis
                  text={item.Text}
                  maxLine='3'
                  ellipsis=', [...]'
                  trimRight
                   basedOn='letters'
                />
                <i> {item.Date}
                </i>
              </div>
            ))}
    </div>
    </div>
    );
  }

  export default PressReleases;