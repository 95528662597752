import React from 'react';
import {
    Link
  } from "react-router-dom";

class About extends React.Component {
    render() {
        return (    
            <div class="tab-content" >
            <br></br><br></br>
               <h4 style={{ paddingLeft: 100, paddingRight: 100 }}> About</h4><br></br>
                <p style={{ paddingLeft: 100, paddingRight: 100 }}> 
                Hello, Welcome to Stockfin. I am <a href="https://www.linkedin.com/in/spasupuleti/" target="_blank">Vasu Pasupuleti</a> and have been working in Big Tech for more than 10 years. Stockfin is born out of two needs. My desire to learn about businesses and to build a service that could be useful to others as well.
                    If you have feedback or want to get in touch with me, please send me a message in <Link to="/Contact">Contact</Link>. 
                </p>
                <p style={{ paddingLeft: 100, paddingRight: 100 }}>
                    Stockfin helps investors to get all the financial information regarding a stock in one place.
                    Information is key to your trade. At Stockfin, you can learn about stock price, quarterly financial results, latest news, videos, press alerts and analysts targets. 
                    If you find issues or bugs, you can create an issue in public github repo. <a href="https://github.com/vaasu1234/stockfin-issues/issues/new/choose" target="_blank">Create an Issue</a>
                </p>
            </div>
        )
    }
}

export default About;