import React from "react";
import Reports from "./Reports"

const Quote = (props) => {

    return (
      <div className="card">
      <table>
        <tr>
        <div className="card-body">
        <h5 className="card-title">Market Price: ${props.quote.MarketPrice} ({props.quote.Change}, {props.quote.ChangesPercentage}%)  </h5>
          <table>
            <tr>
              <th>
                <br></br>
                Previous Close: ${props.quote.MarketPreviousClose} <br></br> 
                Day's Open: ${props.quote.MarketOpen}  <br></br> 
                Day's High: ${props.quote.DayHigh}  <br></br> 
                Day's Low: ${props.quote.DayLow}  <br></br> 
                52 Week High: ${props.quote.FiftyTwoWeekHigh}  <br></br> 
                52 Week Low: ${props.quote.FiftyTwoWeekLow}  <br></br> 
                50 Day Average: ${props.quote.FiftyDayAverage}  <br></br> 
                200 Day Average: ${props.quote.TwoHundredDayAverage} <br></br>
                EarningsPerShare(EPS): {props.quote.EPS} <br></br>
                PriceToEarnings(PE): {props.quote.PE} <br></br>
              </th>
              <th>
                MarketCapitalization: ${props.details.MarketCapitalization} <br></br> 
                RevenueTTM: ${props.details.RevenueTTM} <br></br> 
                GrossProfitTTM: ${props.details.GrossProfitTTM} <br></br> 
                PriceToSalesRatioTTM: {props.details.PriceToSalesRatioTTM} <br></br> 
                PriceToBookRatio: {props.details.PriceToBookRatio} <br></br> 
                ShortPercentFloat: {props.details.ShortPercentFloat} <br></br> 
                LatestQuarterDate: {props.details.LatestQuarterDate} <br></br> 
                LatestQuarterRevenueGrowthYOY: {props.details.QuarterlyRevenueGrowthYOY} <br></br>
                NextEarningsDate: {props.quote.EarningsAnnouncement} <br></br>
              </th>
            </tr>
          </table>
          </div>
        </tr>
        <br></br><br></br>
        <tr>
          <Reports
           reports = {props.reports}
           />
        </tr>
      </table>
      </div>
    );
}

export default Quote;