import React from 'react';
import StockList from "./StockList";

const gainersURL = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/stocks/movers?query=gainers"; 
const losersURL = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/stocks/movers?query=losers"
const activesURL = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/stocks/movers?query=actives"


const SymbolTabsNav = (props) => {
  return (
  <nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-item nav-link active" id="nav-gainers-tab" data-toggle="tab" href="#nav-gainers" role="tab" aria-controls="nav-gainers" aria-selected="true">Gainers</a>
    <a class="nav-item nav-link" id="nav-losers-tab" data-toggle="tab" href="#nav-losers" role="tab" aria-controls="nav-losers" aria-selected="false">Losers</a>
    <a class="nav-item nav-link" id="nav-actives-tab" data-toggle="tab" href="#nav-actives" role="tab" aria-controls="nav-actives" aria-selected="false">Actives</a>
  </div>
</nav>
  );
}

class Movers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      gainers: [],
      losers:[],
      actives:[],
    };
  }

  async componentDidMount() {

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

   fetch(gainersURL, requestOptions)
   .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            gainers: result,
            isLoaded: true
          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )

    fetch(losersURL, requestOptions)
   .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            losers: result,
          });
        },
        (error) => {
          this.setState({
            error
          });
        }
      )

      fetch(activesURL, requestOptions)
      .then(res => res.json())
         .then(
           (result) => {
             this.setState({
               actives: result,
             });
           },
           (error) => {
             this.setState({
               error
             });
           }
         )
      }


      render() {        
        if (this.state.error) {
          return <div>Error: {this.state.error.message}</div>;
        } else if (!this.state.isLoaded) {
          return <div>Loading...</div>;
        } else {
          return (
            <div>
            <SymbolTabsNav />
            <div class="tab-content" id="nav-tabContent">
            {this.state.isLoaded && 
              <div class="tab-pane fade show active" id="nav-gainers" role="tabpanel" aria-labelledby="nav-gainers-tab"> 
                <StockList 
                  movers = {this.state.gainers} 
                />
              </div>
            }
   		
		        {this.state.isLoaded && 
		          <div class="tab-pane fade" id="nav-losers" role="tabpanel" aria-labelledby="nav-losers-tab"> 
		            <StockList 
		              movers = {this.state.losers} 
		            />
		          </div>
		        }

            {this.state.isLoaded && 
		          <div class="tab-pane fade" id="nav-actives" role="tabpanel" aria-labelledby="nav-actives-tab"> 
		            <StockList 
		              movers = {this.state.actives} 
		            />
		          </div>
		        }
            </div>
            </div>
          );
        }
      }

  }

  export default Movers;
