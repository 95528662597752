import React from 'react';

function Contact() {

    const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "021502f8-02a8-4f07-8ce0-e4df671c7222");

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
      setResult(res.message);
    } else {
      console.log("Error", res);
      setResult(res.message);
    }
  };
     
        return (
            <div className="Contact">
            <center> <br></br>
              <h4>Contact Stockfin</h4> <br></br>
              <form onSubmit={onSubmit}>
                <label> Name<br></br> <input type="text" name="name"/></label> <br></br><br></br>
                <label> Email <br></br> <input type="email" name="email"/></label> <br></br><br></br>
                <label> 
                    Message <br></br><textarea name="message" rows={4} cols={40} />
                </label> <br></br><br></br>
                <label>
                <input type="submit" />
                </label>
                
              </form>
              <span>{result}</span>
              </center>
            </div>
          );
}

export default Contact;


