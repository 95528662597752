import React, { useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import image from '../../stockfin.png'
import { Route, Routes, Navigate, Link, Outlet } from 'react-router-dom';


function NavBar(props) {
    return (
        <div>
         <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
            <div className="container">
              <Link className="navbar-brand  mr-auto" to="Stocks"><img src={image} width="40" height="25" alt="" />Stockfin</Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" 
                data-target="#navbarNav" aria-controls="navbarNav" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <div className="navbar-nav">
                <Link className="nav-item nav-link" to="Stocks">Stocks</Link>
                <Link className="nav-item nav-link" to="MarketNews">Markets</Link>
                <Link className="nav-item nav-link" to="News">News</Link>
                <Link className="nav-item nav-link" to="Movers">Movers</Link>
                <Link className="nav-item nav-link" to="Contact">Contact</Link>
                <Link className="nav-item nav-link" to="About">About</Link>
                </div>
              </div>
            </div> 
          </nav>
          <br></br><br></br><hr></hr>
        </div>
        );

        /*
                   <GoogleLogout
                clientId={props.clientId}
                buttonText={props.name + " (Sign Out)"}
                onLogoutSuccess={props.onLogoutSuccess}
                >
            </GoogleLogout> &nbsp; &nbsp; 
            */
}

export default NavBar;
