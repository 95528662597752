import React, { useState, useEffect }  from "react";
 
const YoutubeEmbed = (props) => {
  //console.log(props.videos[0].videoId)
  
  return (
  <div className="video-responsive">
  <br></br>
  {props.videos && props.videos.map(index => (
    <div>  
    <iframe
        width="853"
        height="480"
        src={"https://www.youtube.com/embed/" + index.videoId}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title="video"
      />
      <h2>{index.publishTime}:{index.title}</h2>
      <hr></hr>
      <br></br>
      
      </div>
    ))
  }
  </div> 
  );
}


export default YoutubeEmbed;