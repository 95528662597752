import React from "react";

const StockList = (props) => {

    return (
      <div className="overflow-scroll">
        <div className="card text-left m-3">
            {props.movers && props.movers.map(index => (
              <div className="card-body scroll">
              <h6 className="card-title" style={{ color: 'blue' }}>{index.Name} ({index.Symbol}): {index.Price} ({index.Change}, {index.ChangesPercentage}%)  </h6>
              </div>
            ))}
        </div>
      </div>
    );
}

export default StockList;