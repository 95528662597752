import React from 'react';
import TextTruncate from 'react-text-truncate';
import InfiniteScroll from "react-infinite-scroll-component";

const url = "https://m60j8r33ac.execute-api.us-east-1.amazonaws.com/prod/news"; 

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      value: ' ',
      next:{},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state.next)
    };

   fetch(url, requestOptions)
   .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.newsData,
            next: result.next
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  async handleChange() {
    window.location.reload(false);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state.next)
    };

    fetch(url,requestOptions )
    .then(res => res.json())
       .then(
         (result) => {
           this.setState({
             isLoaded: true,
             items: result.newsData,
             next: result.next
           });
         },
         // Note: it's important to handle errors here
         // instead of a catch() block so that we don't swallow
         // exceptions from actual bugs in components.
         (error) => {
           this.setState({
             isLoaded: true,
             error
           });
         }
       )
    }

    fetchMoreData = () => {
      console.log("fetch More Data Called");

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.state.next)
      };

      fetch(url, requestOptions)
      .then(res => res.json())
         .then(
           (result) => {
             this.setState({
               isLoaded: true,
               items: this.state.items.concat(result.newsData),
               next: result.next
             });
           },
           // Note: it's important to handle errors here
           // instead of a catch() block so that we don't swallow
           // exceptions from actual bugs in components.
           (error) => {
             this.setState({
               isLoaded: true,
               error
             });
           }
         )

         setTimeout(() => 15000);
    };

  render() {
    const { error, isLoaded, items } = this.state;
    
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
        <div style={{textAlign: 'right'}} >
        <button type="button" className="btn btn-primary" style={{textAlign: 'right'}} onClick={this.handleChange}>Refresh</button> &nbsp; &nbsp;
        </div>

        <div className="overflow-scroll">
        <div className="card text-left m-3  ">
          <h5 className="card-header fixed">News Stream</h5>
          <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.fetchMoreData}
          hasMore={true}
          />
          {this.state.items && items.filter((item, idx) => idx).map(item => (
             <div className="card-body scroll">
              <a href={item.newsURL} target="_blank">{item.newsTitle}</a> 
              <br></br> 
              <TextTruncate
               line={3}
               element="span"
               truncateText="…"
              text={item.newsDescription} />
              <br></br> <i> {item.newsSource} &nbsp; &nbsp; 
              {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(item.newsPublishedDate*1000)}
              </i>
            </div>
          ))}
        </div>
        </div>
        </div>
      );
    }
  }
}

export default News;