import React, { useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { BrowserRouter } from 'react-router-dom';
import { News } from '../News';
import { MarketNews } from '../MarketNews';
import { Stocks } from '../Stocks';
import { Movers } from '../Movers';
import { Contact } from '../Contact';
import { About } from '../About';

//import { Route, Switch, Redirect } from 'react-router-dom';
import { Route, Routes, Navigate, Link, Outlet } from 'react-router-dom';

import NavBar from './NavBar'

var clientId =  "608513368619-f4fs40lsi3ptde9m8nsmk1qfanc4jhd1.apps.googleusercontent.com";
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
    clientId = "608513368619-sflnkfaf4ch9h88dfnujoh4edutavqn5.apps.googleusercontent.com"
}

  function Login() {
    console.log("hello")
    const [showloginButton, setShowloginButton] = useState(true);
    const [showlogoutButton, setShowlogoutButton] = useState(false);
    const [sessionID, setSessionID] = useState(true);

    const onLoginSuccess = (res) => {
        console.log('Login Success:', res.profileObj);
        localStorage.setItem('Name', res.profileObj.name);     
        setShowloginButton(false);
      //  setShowlogoutButton(true);
        
        
        const url = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/login"

        const payload = {
          firstName: res.profileObj.givenName,
          lastName: res.profileObj.familyName,
          userID: res.profileObj.email,
          loginTimeStamp: Date.now()
        };
        
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload)
        };

        fetch(url, requestOptions)
        .then(res => res.json())
           .then(
             (result) => {
               setSessionID(result.sessionID)          
             },
             (error) => {
              console.log(error)
            }
           )
    };

    const onLoginFailure = (res) => {
    console.log('Login Failed:', res);
    };

    const onLogoutSuccess = () => {
      alert("You have been logged out successfully");
      setShowloginButton(true);
      setShowlogoutButton(false);
      localStorage.removeItem('Name');  

      const url = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/logout"

      const payload = {
        sessionID: sessionID,
        logoutTimeStamp: Date.now()
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      };

        fetch(url, requestOptions)
        .then(res => res.json())
           .then(
             (result) => {
               setSessionID(result.sessionID)
             },
             (error) => {
              console.log(error)
            }
           )
  };
     

    if(showlogoutButton === true){
        return(
            <div>
            <BrowserRouter>
            <NavBar 
              client = {clientId}
              name = {localStorage.getItem('Name')}
              onLogoutSuccess={onLogoutSuccess}/>
            <Routes> 
            <Route path="/" element={<Stocks />} />    
              <Route exact path="/Stocks" element={<Stocks />} />
              <Route exact path="/News" element={<News />} />
              <Route exact path="/MarketNews" element={<MarketNews />} />
              <Route exact path="/Movers" element={<Movers />} />
              <Route exact path="/Contact" element={<Contact />} />
              <Route exact path="/About" element={<About />} />
            </Routes>
            </BrowserRouter>
          </div>
                   
        )
    }

    return(
      <div >
      <BrowserRouter>
      <NavBar 
        client = {clientId}
        name = {localStorage.getItem('Name')}
        onLogoutSuccess={onLogoutSuccess}/>
      <Routes> 
      <Route path="/" element={<Stocks />} />    
        <Route exact path="/Stocks" element={<Stocks />} />
        <Route exact path="/News" element={<News />} />
        <Route exact path="/MarketNews" element={<MarketNews />} />
        <Route exact path="/Movers" element={<Movers />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/About" element={<About />} />
      </Routes>
      </BrowserRouter>
    </div>
             
  );

 /*   return (
        <div className="g-signin">
        <center style={{display: 'flex', color: "white",
            backgroundColor: "DodgerBlue", justifyContent:'center', alignItems:'center', height: '100vh',  textAlign:'center'}}> 
        <table>
        <thead>
            <tr>
            <h2>Weclome to Stockfin </h2>    
            </tr>
            <tr>
            
                <GoogleLogin
                    clientId={clientId}
                    buttonText="Sign In"
                    onSuccess={onLoginSuccess}
                    onFailure={onLoginFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={true}
                /> 
            </tr>
          </thead>
        </table>
        </center>
        </div>
    ); */
}



export default Login;
