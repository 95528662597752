import React from "react";
import Quote  from "./Quote";
import Reports from "./Reports";
import News from "./News";
import PressReleases from "./PressReleases";
import PriceTarget from "./PriceTarget";
import './Stocks.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import YoutubeEmbed from "./YoutubeEmbed";

const SymbolTabsNav = (props) => {
  return (
  <nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-item nav-link active" id="nav-price-tab" data-toggle="tab" href="#nav-price" role="tab" aria-controls="nav-price" aria-selected="true">Price</a>
    <a class="nav-item nav-link" id="nav-news-tab" data-toggle="tab" href="#nav-news" role="tab" aria-controls="nav-news" aria-selected="false">News</a>
    <a class="nav-item nav-link" id="nav-video-tab" data-toggle="tab" href="#nav-video" role="tab" aria-controls="nav-video" aria-selected="false">Videos</a>
    <a class="nav-item nav-link" id="nav-pressreleases-tab" data-toggle="tab" href="#nav-pressreleases" role="tab" aria-controls="nav-pressreleases" aria-selected="false">PressReleases</a>
    <a class="nav-item nav-link" id="nav-pricetarget-tab" data-toggle="tab" href="#nav-pricetarget" role="tab" aria-controls="nav-pricetarget" aria-selected="false">PriceTarget</a>
  </div>
</nav>
  );
}

class Stocksearch extends React.Component {

  state = {
    symbol: '',
    quote: ' ',
    details: ' ',
    reports: ' ',
    news: ' ',
    videos:' ',
    pressreleases: ' ',
    pricetarget: ' ',
    searchSubmitted: false,
    priceLoaded: false,
    detailsLoaded: false,
    reportsLoaded: false,
    newsLoaded: false,
    videosLoaded:false,
    pressreleasesLoaded: false,
    pricetargetLoaded: false,
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: '',
    stockNames:[],
    stockSymbols:[],
    stockMap:{},
    stockNameMap:{},
    items: [],
  }

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
  }

  async componentDidMount() {

    let url = "https://m60j8r33ac.execute-api.us-east-1.amazonaws.com/prod/symbols"
    fetch(url)
    .then(res => res.json())
       .then(
         (result) => {
           this.setState({
             items: result,
           });
           console.log("items:", this.state.items)

           this.state.items.map((data, key) => {
            this.state.stockNames.push(data.name)
            this.state.stockSymbols.push(data.symbol)
            this.state.stockMap[data.name] = data.symbol
            this.state.stockNameMap[data.symbol] = data.name
          });
  
         },
         // Note: it's important to handle errors here
         // instead of a catch() block so that we don't swallow
         // exceptions from actual bugs in components.
         (error) => {
           this.setState({
             error
           });
         }
       )
   }
  
  

  onChange = (e) => {

    const options = this.state.stockNames
    const userInput = e.currentTarget.value;
    var idx = 0;
    var filteredOptions = options.filter(
      (optionName) =>
        ((optionName.toLowerCase().indexOf(userInput.toLowerCase()) === 0) ||
        this.state.stockMap[optionName].toLowerCase().indexOf(userInput.toLowerCase()) === 0) && (idx++ < 10) 
    );

    this.setState({
      activeOption: 0,
      filteredOptions,
      showOptions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = (e) => {
    this.setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText //map this to symbol from the text
    });
  };
  onKeyDown = (e) => {

    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: this.state.filteredOptions[this.state.activeOption]
      });
    } else if (e.keyCode === 38) {
      if (this.state.activeOption === 0) {
        return;
      }
      this.setState({ activeOption: this.stateactiveOption - 1 });
    } else if (e.keyCode === 40) {
      if (this.state.activeOption === this.state.filteredOptions.length - 1) {
        console.log(this.state.activeOption);
        return;
      }
      this.setState({ activeOption: this.state.activeOption + 1 });
    }
  };

  handleSubmit = (event) => {
    const symbol = this.state.stockMap[this.state.userInput];
    const name = this.state.userInput;
    this.setState (
      {
        searchSubmitted: true,
        priceLoaded: false,
        detailsLoaded: false,
        reportsLoaded: false,
        newsLoaded: false
      }
    )

    var url = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/stocks/quote?symbol="+symbol;
    event.preventDefault();
    fetch(url)
          .then(res => res.json())
          .then((data) => {
             this.setState(
              {
                quote: data,
                priceLoaded: true,
                userInput: ""
              })
              console.log("quote:", this.state.quote)
        })
        .catch(console.log) 

    url = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/stocks/overview?symbol="+symbol;
      fetch(url)
          .then(res => res.json())
          .then((data) => {
             this.setState({
                details: data, 
                detailsLoaded: true 
             })
             console.log("details:", this.state.details)
            }).catch(console.log)
  
    url = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/stocks/reports?symbol="+symbol;
      fetch(url)
          .then(res => res.json())
          .then((data) => {
            this.setState({
              reports: data,
              reportsLoaded: true
            })
            console.log("reports:", this.state.reports)
          })
          .catch(console.log)

    url = "https://m60j8r33ac.execute-api.us-east-1.amazonaws.com/prod/news?symbol="+symbol;
      fetch(url)
        .then(res => res.json())
        .then((data) => {
          this.setState({
            news: data,
            newsLoaded: true
          })
          console.log("news:", this.state.news)
        })
        .catch(console.log)

      url = "https://m60j8r33ac.execute-api.us-east-1.amazonaws.com/prod/videos?symbol="+symbol+"+&name="+name;
      fetch(url)
        .then(res => res.json())
        .then((data) => {
          this.setState({
            videos: data,
            videosLoaded: true
          })
        })
        .catch(console.log)

      //Price Targets; Press Releases
      url = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/stocks/pressreleases?symbol="+symbol;
      fetch(url)
        .then(res => res.json())
        .then((data) => {
          this.setState({
            pressreleases: data,
            pressreleasesLoaded: true
          })
        })
        .catch(console.log)

      url = "https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/stocks/pricetarget?symbol="+symbol;
      fetch(url)
        .then(res => res.json())
        .then((data) => {
          this.setState({
            pricetarget: data,
            pricetargetLoaded: true
          })
        })
        .catch(console.log)

  }

  render () {
    let optionList;
    if (this.state.showOptions && this.state.userInput) {
      if (this.state.filteredOptions.length) {
        optionList = (
          <ul className="list-group"> 
            {this.state.filteredOptions.map((optionName, index) => {
              let className = 'list-group-item';
              if (index === this.state.activeOption) {
                className = 'list-group-item active';
              }
              return (
                <li className={className} key={optionName} onClick={this.onClick}>
                  {optionName}
                </li>
              );
            })}
          </ul>
        );
      }
    }
    return (
      <div>
      <br></br>
        <center>
        <form onSubmit={this.handleSubmit} style={{ marginLeft: '1.2rem' }}>

        <div className="container">
        <div className="row justify-content-center">
        <div className="col-4">
        <input
            className="form-control"
            size = '10'
            value={this.state.userInput}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            placeholder="Enter Stock Name"
            type="search"
          />
        {optionList}
        </div>
        <div className="col-1">
        <button type="button" className="btn btn-primary" type="submit">Search</button>
        </div>
        </div>
        </div>
   
        </form>
        </center>
        <br></br> 
        
        {this.state.searchSubmitted && !this.state.priceLoaded &&
          <ProgressBar animated now={75} label={`75%`} />
        }
          
        {this.state.priceLoaded && 
          <h2 align="center">{this.state.quote.Name}</h2>
        }
        {this.state.priceLoaded && 
          <SymbolTabsNav />
        }
        <div class="tab-content" id="nav-tabContent">
        {this.state.priceLoaded && this.state.detailsLoaded && this.state.reportsLoaded &&
          <div class="tab-pane fade show active" id="nav-price" role="tabpanel" aria-labelledby="nav-price-tab"> 
          <Quote 
          quote = {this.state.quote}
          details = {this.state.details}
          reports = {this.state.reports}
        />
        </div>
        }

        {false &&
          <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">  
          <Reports
           reports = {this.state.reports}
           />
          </div>
        }
        
        {this.state.newsLoaded &&
        <div class="tab-pane fade" id="nav-news" role="tabpanel" aria-labelledby="nav-news-tab"> 
        <News
           news = {this.state.news}
           />
        </div>
        }

        {
         <div class="tab-pane fade" id="nav-video" role="tabpanel" aria-labelledby="nav-video-tab"> 
          <center>
          {this.state.videosLoaded &&
            <YoutubeEmbed 
            videos= {this.state.videos} /> }          
          </center>
        </div>
        }

        {
         <div class="tab-pane fade" id="nav-pressreleases" role="tabpanel" aria-labelledby="nav-pressreleases-tab"> 
          {this.state.pressreleasesLoaded &&
            <PressReleases
            pressreleases= {this.state.pressreleases} /> }          
        </div>
        }


        {
         <div class="tab-pane fade" id="nav-pricetarget" role="tabpanel" aria-labelledby="nav-pricetarget-tab"> 
          {this.state.pricetargetLoaded &&
            <PriceTarget 
            pricetarget= {this.state.pricetarget} /> }          
        </div>
        }



        </div>
        </div>
        )
      }
    }

class Stocks extends React.Component {

  render() {
    return (
      <div>
      <Stocksearch />
      </div>
    );
  }
}

export default Stocks;