import React from 'react';
import TextTruncate from 'react-text-truncate';

const url = "https://m60j8r33ac.execute-api.us-east-1.amazonaws.com/prod/news"; 
const indexURL = " https://3ayrywpdk2.execute-api.us-east-1.amazonaws.com/prod/stocks/indices"

const StockIndexes = (props) => {


  return (
    <div className="overflow-scroll">
    <div className="card text-left m-3  ">
          <h5 className="card-header fixed">Stock Indices</h5>
          {props.indices && props.indices.map(index => (
            <div className="card-body scroll">
            <h5 className="card-title">{index.Name}: {index.MarketPrice} ({index.Change}, {index.ChangesPercentage}%)  </h5>
            DayLow: {index.DayLow};&nbsp; DayHigh: {index.DayHigh};&nbsp; 52WeekLow: {index.FiftyTwoWeekLow};&nbsp; 52WeekHigh:{index.FiftyTwoWeekHigh}
            </div>
          ))}
    </div>
    </div>  
  );

}

class MarketNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      indices:[],
      value: ' ',
      indices:{},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

   fetch(url, requestOptions)
   .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            items: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )

    fetch(indexURL, requestOptions)
   .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            indices: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )



  }

  async handleChange() {
    window.location.reload(false);

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(url,requestOptions )
    .then(res => res.json())
       .then(
         (result) => {
           this.setState({
             isLoaded: true,
             items: result,
           });
         },
         // Note: it's important to handle errors here
         // instead of a catch() block so that we don't swallow
         // exceptions from actual bugs in components.
         (error) => {
           this.setState({
             isLoaded: true,
             error
           });
         }
       )
    }

    

  render() {
    const { error, isLoaded, items } = this.state;
    
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
        <div style={{textAlign: 'right'}} >
        <button type="button" className="btn btn-primary" style={{textAlign: 'right'}} onClick={this.handleChange}>Refresh</button> &nbsp; &nbsp;
        </div>
         <StockIndexes 
           indices = {this.state.indices}
         /> 
        <div className="overflow-scroll">
        <div className="card text-left m-3  ">
          <h5 className="card-header fixed">Market News</h5>

          {this.state.items && items.filter((item, idx) => idx).map(item => (
             <div className="card-body scroll">
              <a href={item.newsURL} target="_blank">{item.newsTitle}</a> 
              <br></br> 
              <TextTruncate
               line={3}
               element="span"
               truncateText="…"
              text={item.newsDescription} />
              <br></br> <i> {item.newsSource} &nbsp; &nbsp; 
              {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(item.newsPublishedDate*1000)}
              </i>
            </div>
          ))}
        </div>
        </div>
        </div>
      );
    }
  }
}

export default MarketNews;
