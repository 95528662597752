import React from "react";
import LinesEllipsis from 'react-lines-ellipsis'

const News = (props) => {
  return (
    <div className="overflow-scroll">
    <div className="card text-left m-3">
      {props.news.filter((item, idx) => idx < 200).map(item => (
               <div className="card-body scroll">
                <a href={item.newsURL} target="_blank">{item.newsTitle}</a> 
                <br></br>            
                <LinesEllipsis
                  text={item.newsDescription}
                  maxLine='3'
                  ellipsis=', [...]'
                  trimRight
                   basedOn='letters'
                />
                <i> {item.newsSource} &nbsp; &nbsp; 
                {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(item.newsPublishedDate*1000)}
                </i>
              </div>
            ))}
    </div>
    </div>
    );
  }

  export default News;